import { Link } from "gatsby";
import React, { Fragment } from "react";
import styled from "styled-components";

const Banner = styled.div`
  color: #254e69;
  font-size: 3em;
  font-weight: 700;
`;

function NotFound() {
  return (
    <Fragment>
      <Banner>Nothing to see here...</Banner>
      <p>
        You can <Link to="/">go back home</Link> anytime.
      </p>
    </Fragment>
  );
}

export default NotFound;
